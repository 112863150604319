.#{shared-var("PageTemplateBS") }-#{shared-var("AssemblyGuidePageBS") } {
  h1 {
    @extend .font-weight-bold;
  }

  h2 {
    @extend .h4;
  }

  h3 {
    @extend .font-weight-bold;
    font-size: inherit;
  }

  .media-object {
    @extend .px-0;

    &-image {
      @include media-breakpoint-down(md) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      @extend .pl-0;
      picture {
        @extend .text-left;
      }
      img {
        // min-height: unset;
        max-width: 100% !important;
        min-width: unset !important;
      }
    }
    &-body {
      @extend .pr-0;
      @include media-breakpoint-down(md) {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
      }
    }
  }

  .media-embeder-list {
    @extend .mx-0;
    &-item.row {
      & > div {
        @extend .px-0;
        @extend .text-left;
      }
      .media-embeder {
        @extend .px-0;
      }
      h4 {
        @include media-breakpoint-up(md) {
          text-align: left !important;
        }
      }
    }
  }

  .newspaper-jump {
    @include media-breakpoint-down(md) {
      padding-left: 0 !important;
      padding-right: 0 !important;

      h2 {
        text-align: center !important;
      }
    }

    &-col {
      .article {
        @extend .p-2;
        @extend .mb-3;
        @extend .bg-light;
      }
    }
  }

  .title-text-container {
    &-title,
    &-text {
      @extend .px-0;
    }
  }
}
